.vjs-control-bar {
    width: unset !important;
    background: #000000 !important;
}

.vjs-big-play-button {
    border-radius: 50% !important;
    width: 2em !important;
    height: 2em !important;
    line-height: 1.9em !important;
}
