html {
    font-size: 16px;
    width: 100%;
}

@media screen and (min-width: 1921px) {
    html {
        font-size: calc(16px + 6 * ((100vw - 1920px) / 2176));
    }
}

@media screen and (max-width: 720px) {
    html {
        font-size: calc(13px + 6 * ((100vw - 320px) / 680));
    }
}

a {
    all: unset;
    font: inherit;
    cursor: pointer;
}

p{
    line-height: 1.4;
}

a:hover {
    opacity: 0.7;
}

body {
    color: #FFFFFF;
    margin: 0;
    padding: 0;
    /* height: 100vh; */
    box-sizing: border-box;
    overflow-y: visible;
    width: 100%;
    background: var(--page-background);
    --black-base: #0f0f0f;
    --page-background: #0f0f0f;
    --section-background: #000000;
    --navbar-color: #E5383B;
    --topbar-color: #FFFFFF;
    --login-error: #660708;
    --login-button: #A4161A;
    --row-odd-background: #fafafa;
    --row-even-background: #d4d4d4;
    --text-over-color:#fafafa;
    --primary: #7c6bb4;
    --player-icons-height: 44px;
    --max-distance-slider: 16px;
    --min-distance-slider: 8px;

    /*dimensions vars*/
    --max-content-width: 1340px;
    --card-width: 22vw;
    --gap-between-comps-home: 138px;
    --gap-between-comps-home-mobile: 50px;

    /*font size specs vars*/
    --title-h1: 66px;
    --title-h2: 46px;
    --title-h4: 34px;
    --section-fs: 46px;
    --title-fs: 34px;
    --structure-course-title: 25px;
    --big-parag-fs: 24px;
    --parag-body-fs: 20px;
    --author-body-desc: 23px;
    --footer-text: 13px;
    --surtitle-item: 30px;
    --badge-font-size: 13px;

    /*font size mobile version */
    --title-h1-mobile: 37px;
    --section-fs-mobile: 30px;
    --title-h2-mobile: 25px;
    --title-h4-mobile: 25px;
    --list-title-fs-mobile: 25px;
    --big-parag-fs-mobile: 21px;
    --parag-body-fs-mobile: 18px;
    --authors-text-mobile: 15px;
    --button-body-mobile: 20px;
    --surtitle-fs: 20px;
    --subtitle-fs: 25px;
    --card-width-responsive: calc(var(--max-content-width) / 3 - 20px );
    font-family: 'Maison Neue', sans-serif;
}

@media (max-width: 1025px){
    body{
        --card-width-responsive: calc((100vw - 70px) / 2 );
    }
}
@media (max-width: 600px){
    body{
        --card-width-responsive: calc(100vw - 40px);
    }
}

@media (min-width: 1025px) and (max-width: 1370px){
    body{
        --max-content-width: calc(100vw - 60px);
    }
}
#root {
    box-sizing: border-box;
    background: var(--page-background);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    height: 100%;

}

*, *::before, *::after {
    box-sizing: border-box;
}

/*region VideoJS*/
.vjs-big-play-button {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;

}

.indexCustomization{
    z-index: 2;
}

/*endregion*/

/*region Font Maison Nueue */
@font-face {
    font-family: "Maison Neue";
    src: local("Maison Neue"),
    url("./fonts/Maison Neue/MaisonNeue-ExtraThin.otf") format("opentype");
    font-weight: 100;
}

@font-face {
    font-family: "Maison Neue";
    src: local("Maison Neue"),
    url("./fonts/Maison Neue/MaisonNeue-Thin.otf") format("opentype");
    font-weight: 200;
}

@font-face {
    font-family: "Maison Neue";
    src: local("Maison Neue"),
    url("./fonts/Maison Neue/MaisonNeue-Light.otf") format("opentype");
    font-weight: 300;
}

@font-face {
    font-family: "Maison Neue";
    src: local("Maison Neue"),
    url("./fonts/Maison Neue/MaisonNeue-Book.otf") format("opentype");
    font-weight: 400;
}

@font-face {
    font-family: "Maison Neue";
    src: local("Maison Neue"),
    url("./fonts/Maison Neue/MaisonNeue-Medium.otf") format("opentype");
    font-weight: 500;
}

@font-face {
    font-family: "Maison Neue";
    src: local("Maison Neue"),
    url("./fonts/Maison Neue/MaisonNeue-Demi.otf") format("opentype");
    font-weight: 600;
}

@font-face {
    font-family: "Maison Neue";
    src: local("Maison Neue"),
    url("./fonts/Maison Neue/MaisonNeue-Bold.otf") format("opentype");
    font-weight: 700;
}
@font-face {
    font-family: "Maison Neue";
    src: local("Maison Neue"),
    url("./fonts/Maison Neue/MaisonNeue-ExtraBold.otf") format("opentype");
    font-weight: 800;
}

@font-face {
    font-family: "Maison Neue";
    src: local("Maison Neue"),
    url("./fonts/Maison Neue/MaisonNeue-Black.otf") format("opentype");
    font-weight: 900;
}
/*endregion*/

/*region Font Maison Neue Italic*/

@font-face {
    font-family: "Maison Neue";
    src: local("Maison Neue"),
    url("./fonts/Maison Neue/MaisonNeue-Book.otf") format("opentype");
}

@font-face {
    font-family: "Maison Neue";
    src: local("Maison Neue"),
    url("./fonts/Maison Neue/MaisonNeue-ExtraThinItalic.otf") format("opentype");
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: "Maison Neue";
    src: local("Maison Neue"),
    url("./fonts/Maison Neue/MaisonNeue-ThinItalic.otf") format("opentype");
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: "Maison Neue";
    src: local("Maison Neue"),
    url("./fonts/Maison Neue/MaisonNeue-LightItalic.otf") format("opentype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Maison Neue";
    src: local("Maison Neue"),
    url("./fonts/Maison Neue/MaisonNeue-BookItalic.otf") format("opentype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Maison Neue";
    src: local("Maison Neue"),
    url("./fonts/Maison Neue/MaisonNeue-MediumItalic.otf") format("opentype");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Maison Neue";
    src: local("Maison Neue"),
    url("./fonts/Maison Neue/MaisonNeue-DemiItalic.otf") format("opentype");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "Maison Neue";
    src: local("Maison Neue"),
    url("./fonts/Maison Neue/MaisonNeue-BoldItalic.otf") format("opentype");
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: "Maison Neue";
    src: local("Maison Neue"),
    url("./fonts/Maison Neue/MaisonNeue-ExtraBoldItalic.otf") format("opentype");
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: "Maison Neue";
    src: local("Maison Neue"),
    url("./fonts/Maison Neue/MaisonNeue-BlackItalic.otf") format("opentype");
    font-weight: 900;
    font-style: italic;
}
/*endregion*/

/*region SF Pro Display */

@font-face {
    font-family: "SF Pro Display";
    src: local("SF Pro Display"),
    url("./fonts/SF Pro Display/SF-Pro-Display-Light.otf") format("opentype");
    font-weight: 300;
}

@font-face {
    font-family: "SF Pro Display";
    src: local("SF Pro Display"),
    url("./fonts/SF Pro Display/SF-Pro-Display-Regular.otf") format("opentype");
    font-weight: 400;
}

@font-face {
    font-family: "SF Pro Display";
    src: local("SF Pro Display"),
    url("./fonts/SF Pro Display/SF-Pro-Display-Semibold.otf") format("opentype");
    font-weight: 600;
}

@font-face {
    font-family: "SF Pro Display";
    src: local("SF Pro Display"),
    url("./fonts/SF Pro Display/SF-Pro-Display-Bold.otf") format("opentype");
    font-weight: 700;
}

/*endregion*/

/*region SF Pro Display Italic */

@font-face {
    font-family: "SF Pro Display";
    src: local("SF Pro Display"),
    url("./fonts/SF Pro Display/SF-Pro-Display-LightItalic.otf") format("opentype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "SF Pro Display";
    src: local("SF Pro Display"),
    url("./fonts/SF Pro Display/SF-Pro-Display-RegularItalic.otf") format("opentype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "SF Pro Display";
    src: local("SF Pro Display"),
    url("./fonts/SF Pro Display/SF-Pro-Display-SemiboldItalic.otf") format("opentype");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "SF Pro Display";
    src: local("SF Pro Display"),
    url("./fonts/SF Pro Display/SF-Pro-Display-BoldItalic.otf") format("opentype");
    font-weight: 700;
    font-style: italic;
}

/*endregion*/

/* region Helvetica neue */

@font-face {
    font-family: "Helvetica Neue";
    src: local("Helvetica Neue"),
    url("./fonts/Helvetica Neue/Helvetica-Neue.ttf") format("truetype");
    font-weight: 300;
}
/* endregion */

/*region Splide*/
.splide__arrow {
    height: 65px!important;
    width: 65px!important;
}

.splide__arrow:disabled {
    display: none;
}
@media screen and (max-width: 1440px) {
    .splide__arrow {
        height: 50px!important;
        width: 50px!important;
    }
}

.splide__pagination__page {
    border-radius: 0 !important;
    width: 28px !important;
    margin: 0 2px !important;
}

.splide__pagination__page.is-active {
    transform: scale(1) !important;
}

@media screen and (max-width: 1024px) {
    .splide__arrow {
        display: none!important;
    }
}

/*endregion*/
